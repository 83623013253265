.project-text .image img {
  width: 100%;
}

.project-text table h2 {
  color: #4F4F6F;
}
.project-text td {
  padding: 20px;
  border: 2px solid #4F4F6F;
}