.navPills {
  position: relative;
  border-bottom: 1px solid var(--blue);
  text-transform: uppercase;
  font-size: 13px;
}
.navPills .nav-item {
  cursor: pointer;
}
.navLink {
  color: var(--bs-gray) !important;
  background-color: unset !important;
  border-radius: unset !important;
  cursor: pointer;
}
.logoutBtn {
  font-family: "Gotham Pro";
  font-size: 14px;
  border: none !important;
  padding: 10px 15px 6px 20px;
}
.welcomeBtnWrapper {
  height: 300px;
}
.navProjectIcon {
  position: absolute;
  right: -15px;
  top: -10px;
}
.navProjectIcon img {
  padding: 6px;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid #9babab;
  max-height: 80px;
  width: auto;
  -webkit-box-shadow: 0 0 13px 7px rgb(204 204 223 / 50%);
  -moz-box-shadow: 0 0 13px 7px rgba(204, 204, 223, 0.5);
  box-shadow: 0 0 13px 7px rgb(204 204 223 / 50%);
}
.btnTrade{
  width: 100%;
}
.bidUserImg div{
  display: flex;
  align-content: space-around;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background: var(--blue);
  height: 100%;
  min-height: 70px;
}
input.bid_ask_form{
  padding: 10px;
  border: none;
  border-radius: unset;
  border-bottom: 1px solid #ced4da;
}
.bidAskWrap{
  padding: 30px 50px 30px 30px;
}
@media (max-width: 767px) {
  .welcomeBtnWrapper {
    height: unset;
  }
}
@media (max-width: 576px) {
  .navLink {
    padding: 6px;
    font-size: 12px;
  }
}