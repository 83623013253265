@charset "UTF-8";

@font-face {
  font-family: "Gotham Pro Bold";
  src: url("../fonts/GothamPro-Bold.eot");
  src: url("../fonts/GothamPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/GothamPro-Bold.woff2") format("woff2"),
    url("../fonts/GothamPro-Bold.woff") format("woff"),
    url("../fonts/GothamPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro Light";
  src: url("../fonts/GothamPro-Light.eot");
  src: url("../fonts/GothamPro-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/GothamPro-Light.woff2") format("woff2"),
    url("../fonts/GothamPro-Light.woff") format("woff"),
    url("../fonts/GothamPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro Light Italic";
  src: url("../fonts/GothamPro-LightItalic.eot");
  src: url("../fonts/GothamPro-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/GothamPro-LightItalic.woff2") format("woff2"),
    url("../fonts/GothamPro-LightItalic.woff") format("woff"),
    url("../fonts/GothamPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro Black";
  src: url("../fonts/GothamPro-Black.eot");
  src: url("../fonts/GothamPro-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/GothamPro-Black.woff2") format("woff2"),
    url("../fonts/GothamPro-Black.woff") format("woff"),
    url("../fonts/GothamPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamPro.eot");
  src: url("../fonts/GothamPro.eot?#iefix") format("embedded-opentype"),
    url("../fonts/GothamPro.woff2") format("woff2"),
    url("../fonts/GothamPro.woff") format("woff"),
    url("../fonts/GothamPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro Medium Italic";
  src: url("../fonts/GothamPro-MediumItalic.eot");
  src: url("../fonts/GothamPro-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/GothamPro-MediumItalic.woff2") format("woff2"),
    url("../fonts/GothamPro-MediumItalic.woff") format("woff"),
    url("../fonts/GothamPro-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro Medium";
  src: url("../fonts/GothamPro-Medium.eot");
  src: url("../fonts/GothamPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/GothamPro-Medium.woff2") format("woff2"),
    url("../fonts/GothamPro-Medium.woff") format("woff"),
    url("../fonts/GothamPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

:root {
  --violet: #6e45e2;
  --blue: #222890;
  --lightblue: #0d6efd;
  --white: #fffafa;
  --red: #ef2000;
  --pink: #ff56b5;
  --orangered: #ef4d07;
  --orange: #362815;
  --yellow: #f8bb00;
  --green: #198754;
  --lgrey: #dee2e6;
}

/* Global */
body {
  font-family: "Gotham Pro";
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-image: url(../img/home-bgr.jpg);
  background-color: var(--blue);
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  color: #4f4f6f;
  position: relative;
  overflow-x: hidden;
}

p,
ul,
ol {
  font-size: 14px;
  color: #4f4f6f;
  font-family: "Gotham Pro";
  line-height: 1.6;
}

strong,
b {
  font-family: "Gotham Pro Bold";
}

a {
  color: var(--blue);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gotham Pro Medium";
}

.text-danger {
  color: #a21b29 !important;
}

.nav-link:focus,
.nav-link:hover {
  color: var(--white);
}

.header .nav-link:hover {
  border-bottom: 1px solid #fff;
}
.dropdownMenu.dropdown button {
  background: transparent;
  border: unset;
  font-family: "Gotham Pro";
  font-size: 12px;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.languageSwitch .dropdown-toggle.btn.btn-primary{
  padding: 10px 20px;
  margin-left: 5px;
}
.text-white {
  color: var(--white) !important;
}
.text-primary {
  color: var(--blue) !important;
}
.text-info {
  color: var(--violet) !important;
}
.bg-transparent {
  background: transparent !important;
}
.bg-primary {
  background: var(--blue) !important;
  color: var(--white) !important;
}
.bg-midgrey {
  background-color: #f2f5f7;
}
.bg-success {
  background-color: var(--green) !important;
  border-color: var(--green) !important;
}
.bg-danger {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.bg-pink {
  background: var(--pink) !important;
  color: var(--white) !important;
}
.bg-info {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}
.bg-white {
  background: var(--white) !important;
}
.sticky-top {
  padding: 0.5rem 1.5rem !important;
}
.btn-primary {
  color: var(--white);
  font-family: "Gotham Pro Bold";
  margin: 10px 0;
  background-color: var(--violet);
  border: 1px solid var(--violet);
  text-transform: uppercase;
  box-shadow: unset !important;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 25px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--blue);
  border: 1px solid var(--blue);
}

.btn-primary.disabled,
.btn-primary:disabled {
  opacity: 0.7;
  background-color: var(--violet);
  border: 1px solid var(--violet);
}

.btn-secondary {
  color: var(--white);
  font-family: "Gotham Pro Bold";
  margin: 10px 5px;
  text-transform: uppercase;
  box-shadow: unset !important;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 25px;
}

.btn-lg {
  padding: 15px 45px;
}
.btn-sm {
  padding: 0.25rem 0.875rem;
  font-size: 12px;
}

.invalid-feedback {
  margin-left: 15px;
}

span.badge.rounded-pill {
  width: fit-content;
  padding: 5px 10px 3px 10px;
  text-transform: uppercase;
  z-index: 1;
}
/* End Global */

/* Header */
.nav-link {
  color: var(--white);
}

.header .navbar-brand img {
  width: 265px;
  height: auto;
}

.header .nav-link {
  font-size: 12px;
  color: var(--white) !important;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 300ms;
  text-align: center;
  height: fit-content;
  padding: 0.65rem !important;
}

.header .nav-item {
  align-items: center;
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
  border-bottom: 1px solid var(--white);
}

.profile-menu.nav-link {
  padding: 10px 20px !important;
  border-radius: 30px;
  border: 1px solid var(--white);
}

.offcanvas-header img {
  width: 190px;
}

.offcanvas-header .btn-close {
  background-image: url("../img/close.svg");
}
/* End Header */

/* Footer */
footer {
  background-image: url(../img/footer-home-bgr.jpg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

footer .f-site-title {
  color: #c7c7c7;
  font-size: 14px;
  font-family: "Gotham Pro Medium";
  margin-top: 20px;
}

footer .f-site-description {
  color: #c7c7c7;
  font-size: 12px;
  font-family: "Gotham Pro Light";
}

footer .logo {
  width: 100%;
  max-width: 261px;
}

footer .f-title {
  font-size: 18px !important;
  color: var(--white) !important;
  font-family: "Gotham Pro Bold";
  margin-bottom: 20px;
}

footer .info-column.nav {
  padding-left: 3rem !important;
}

footer .nav-item {
  margin-bottom: 15px;
}

footer .nav-link {
  padding: 0;
  color: #a6a8d1;
  font-size: 12px;
  font-family: "Gotham Pro";
  text-transform: uppercase;
}

footer .social {
  color: #a6a8d1;
}

footer .social a {
  padding: 0 10px;
}

footer .social a i {
  font-size: 24px;
}

footer .signature {
  background-color: #171c68;
  color: var(--white);
  font-size: 12px;
  font-family: "Gotham Pro Light";
  text-align: center;
  border-top: 2px solid #2f3377;
  padding: 30px 0;
}

/* End Footer */
.content {
  background: var(--white);
  height: 200px;
}

.titleText {
  font-family: "Gotham Pro Bold";
  font-size: 35px;
  color: #4f4f6f;
  margin: 15px 0 15px 0;
  line-height: 1;
}

.subTitle {
  font-size: 18px;
}

.nftBtn .btn {
  margin: 0 5px 0 0;
  white-space: nowrap;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-bottom: 3px solid var(--blue);
  color: var(--blue) !important;
}

.form-switch .form-check-input {
  width: 4em;
  height: 2em;
}

.form-switch label.form-check-label {
  padding-left: 10px;
  padding-top: 8px;
}

.form-check-input:checked {
  background-color: var(--blue);
  border-color: var(--blue);
}

.progress {
  height: 1.2rem;
}

.progress-bar {
  background-color: var(--blue);
}

.form-control,
.form-select {
  border-radius: 25px;
  padding: 10px 25px;
  font-size: 13px;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ced4da;
  outline: 0;
  box-shadow: unset;
}

.issuer-help-form .input-group-text {
  background: #fff;
  color: #ced4da;
  border-radius: 25px;
  border-right: unset;
}

.issuer-help-form .input-group > .form-control {
  border-left: unset;
  padding-left: 5px;
}

.issuer-help-form span.input-group-text i {
  padding-left: 5px;
}

.submitBtnPrimary,
.submitBtnPrimary:hover,
.submitBtnPrimary:focus,
.submitBtnPrimary:active {
  padding: 10px 20px;
}

.paymentDropdown button,
.paymentDropdown.show > .btn-primary.dropdown-toggle {
  background-color: #4f4f6f;
  color: white;
  padding: 12px 16px;
  font-size: 14px;
  border: none;
  border-radius: unset;
  font-family: "Gotham Pro";
}

.pageHeader {
  padding: 30px 0;
  background-color: #f2f5f7;
  border-bottom: 1px solid #d8d8d8;
}

.pageContent {
  min-height: 40vh;
  padding: 40px 0;
  background: #fff;
  background-image: url("/src/assets/img/profile-content-bgr.png");
  background-repeat: no-repeat;
  background-position: top 100px center;
  background-size: 100% auto;
}

.input-group button,
.input-group button:focus {
  border: 1px solid #ced4da;
  border-radius: 0 25px 25px 0;
  box-shadow: unset;
}

.privkeydone {
  padding: 0px 20px 0 0;
  border-radius: 25px;
}

i.fa.fa-copy {
  cursor: pointer;
}

.card-footer .nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

ul.pay-success-info {
  width: 75%;
  margin: auto;
}
p.text-danger,
p.text-success {
  font-family: "Gotham Pro";
}
.sel2-primary > div > div > div > div,
.sel2-primary > div > div > div > div:hover {
  background: var(--blue);
  color: #fff;
  border-radius: 0;
}
.tradeWrapper .form-control {
  padding: 0;
  font-size: 18px;
  font-family: "Gotham Pro Bold";
}

/* Animated Checkmark */
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin-right: 5px;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

/* 404 Page */
.error-page p {
  font-size: 150px;
  font-weight: 600;
  font-family: "Gotham Pro Bold";
}
span.error {
  margin-left: 11px;
}

/* Pagination */
.page-link {
  color: var(--violet);
}
.active > .page-link,
.page-link.active {
  background-color: var(--blue);
  border-color: var(--blue);
}

@media (min-width: 992px) {
  .policies-page, .policyContent{
    padding: 5rem
  }
  table.secondary-market th {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

table {
  font-family: "Gotham Pro Light";
}

/* Responsive table */
@media (max-width: 992px) {
  body {
    background-size: auto;
  }
  .policyContent {
    padding: 5rem;
  }
  .offcanvas.show {
    background: #2f3377 !important;
  }

  .profile-menu.nav-link {
    text-align: center;
    margin-top: 15px;
  }

  .navbar-nav .nav-link br {
    display: none;
  }

  ul.pay-success-info {
    width: 100% !important;
  }
  .tbl-responsive.table {
    --bs-table-striped-bg: unset;
  }
  .tbl-responsive thead {
    display: none;
  }
  .tbl-responsive tr {
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.175);
    display: flex;
    flex-direction: column;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    margin-bottom: 1.5rem;
  }
  .tbl-responsive td {
    display: flex;
    width: 100%;
  }
  .tbl-responsive td .btn {
    position: relative;
    left: -10px;
  }
  .tbl-responsive td:before {
    content: attr(data-header);
    font-weight: bold;
    color: #000;
    margin-right: 10px;
  }
}

@media (max-width: 576px) {
  .policies-page, .policyContent{
    padding: 5rem 0;
  }
  .header .navbar-brand img {
    width: 190px;
  }

  footer {
    text-align: center;
  }

  footer .info-column.nav {
    padding: 20px !important;
  }

  footer .f-title {
    margin-bottom: 0;
  }

  footer .col-link {
    margin-bottom: 20px;
  }

  footer .col-link:last-child {
    margin-bottom: unset;
  }

  .mbsm-3 {
    margin-bottom: 7px !important;
  }

  .kyc-profile {
    flex-direction: column-reverse;
    text-align: center;
  }
  .kyc-profile img {
    margin-bottom: 20px;
  }

  .project-text img {
    width: 100% !important;
    height: auto !important;
  }

  .alert.alert-secondary.show {
    padding: 10px !important;
  }

  .alert span.p-5 {
    padding: 5px !important;
    word-break: break-all;
    margin: 0;
  }

  ul.pay-success-info {
    padding-left: 0;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.toggle-switch {
  position: relative;
  width: 80px;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  cursor: pointer;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  transition: margin 0.15s ease-in 0s;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
  float: left;
  width: 70px;
  line-height: 34px;
  background: var(--lgrey);
  text-align: center;
}
.toggle-switch-inner:before {
  content: attr(data-yes);
  border-radius: 16px 0 0 16px;
}
.toggle-switch-inner:after {
  content: attr(data-no);
  border-radius: 0 16px 16px 0;
}
.toggle-switch-switch {
  width: 100%;
  margin: 10px;
  position: absolute;
  top: -10px;
  bottom: -2px;
  right: -70px;
  border-radius: 20px;
  transition: all 0.15s ease-in 0s;
  background: var(--blue);
  color: #fff;
  text-align: center;
  padding-top: 7px;
}
.toggle-switch-label.checked .toggle-switch-switch {
  right: -10px;
}
.chart-wrapper {
  position: relative;
}
.watermark {
  background-image: url("../img/trading-view-logo.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 30px;
  height: 30px;
  padding-right: 2px;
  z-index: 9;
  top: calc(100% - 60px);
  left: 5px;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
  border-radius: 30px;
}
.watermark:hover {
  width: 148px;
}
