.pageHeader {
  padding: 20px 0;
  background-color: #f2f5f7;
  border-bottom: 1px solid #d8d8d8;
}
.pageTitle {
  padding: 0;
  color: #4f4f6f;
  font-size: 24px;
  font-family: "Gotham Pro Bold";
  text-align: left;
  position: relative;
  margin: 0;
  text-transform: uppercase;
}
.pageTitle:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -13px;
  width: 70px;
  background-color: var(--violet);
  height: 1px;
}
.projectSubHeading span {
  color: #4f4f6f;
  font-size: 12px;
  font-family: "Gotham Pro Bold";
  text-transform: uppercase;
}
.projectSubHeading span img {
  margin-right: 6px;
}
.projectCard {
  border-radius: 15px;
  height: 100%;
  background: #f8f9fa;
  transition: all 300ms;
}
.projectCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}
.imgContainer {
  padding-top: 64.25%;
  position: relative;
  width: 100%;
}
.nftImgContainer {
  padding: 40%;
  position: relative;
  width: 100%;
}
.projectImage {
  background: #e9eef1;
  background-position: center;
  background-size: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-repeat: no-repeat;
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.imgContainerNews {
  padding-top: 60%;
  position: relative;
  width: 100%;
}
.projectNewsImage {
  background: #e9eef1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.projectStatus {
  position: absolute;
  right: 10px;
  top: 10px;
}
.projectStatus2 {
  position: absolute;
  right: 10px;
  top: 30px;
}
.projectType {
  position: relative;
  top: -26px;
  padding: 0 1rem;
  min-height: 50px;
}
.projectIconImg {
  max-height: 50px;
  max-width: 136px;
  padding: 2px;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid #9babab;
}
.projectCardTitle {
  color: #4f4f6f;
  font-size: 18px;
  font-family: "Gotham Pro Bold";
}
.projectCardBody {
  font-size: 13px;
}
.projectFooter {
  background: transparent;
  border-top: unset;
}
.projectRightBar h4 {
  font-size: 22px;
  color: #4f4f6f;
  font-family: "Gotham Pro Medium";
  margin-bottom: 30px;
}
.shortDescr strong {
  font-size: 14px;
  color: #4f4f6f;
  font-family: "Gotham Pro Bold";
  line-height: 1.6;
}
.daysCount {
  color: var(--lightblue);
  font-size: 46px;
  font-family: "Gotham Pro Light";
}
.hBtnInvest {
  padding: 10px 20px 8px 20px;
}
.investCol1,
.investCol2 {
  border-right: 1px solid var(--lgrey);
}
.fileType {
  padding: 8px;
  border: 1px solid #a21b29;
  background: #a21b29;
  color: #fff;
  text-transform: uppercase;
  width: -moz-fit-content;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
}
.fileName {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  padding-left: 1rem;
}

@media (max-width: 576px) {
  .investCol1,
  .investCol2 {
    border-right: unset;
    border-bottom: 1px solid var(--lgrey);
  }
}