.loginFormContainer,
.signupFormContainer {
  background-color: #fff;
  box-shadow: 0 30px 24.36px 4.64px rgb(0 52 29 / 23%);
  transition: all 0.3s ease-in-out;
}
.title {
  font-family: "Gotham Pro Bold";
  font-size: 37px;
  text-align: center;
  border-bottom: 1px solid var(--violet);
  text-transform: uppercase;
  background: #fff;
}
.resetPasswordTitle {
  font-size: 30px;
}
.formLabel {
  margin-bottom: 0.5rem;
  font-family: "Gotham Pro";
  color: #595959;
  font-size: 10px;
  padding-left: 15px;
  text-transform: uppercase;
}
.btnLogin {
  display: block;
  margin: auto;
  width: 60%;
  padding: 15px 50px 13px;
  font-size: 13px;
}
.socialLogin {
  align-items: center;
}
.facebook,
.facebook:hover,
.facebook:focus {
  position: relative;
  width: 50px;
  height: 50px;
  color: #4267b2;
  border: 2px solid #1f95ff;
  border-radius: 50%;
  margin: 0 5px;
}
.facebookI {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 23px;
}
.google,
.google:hover,
.google:focus {
  position: relative;
  width: 50px;
  height: 50px;
  color: #dd4b39;
  border: 2px solid #ed1d25;
  border-radius: 50%;
  margin: 0 5px;
}
.googleI {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 23px;
}
button.likeAnchor,
button.likeAnchor:focus,
button.likeAnchor:hover,
button.likeAnchor:active {
  background: unset;
  color: var(--violet);
  border: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  box-shadow: unset;
  font-family: unset;
  text-transform: uppercase;
}
.modalClose {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}
.pageTitle {
  font-size: 24px;
  font-family: "Gotham Pro Bold";
  position: relative;
}
.pageTitle:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -13px;
  width: 70px;
  background-color: var(--violet);
  height: 1px;
}
